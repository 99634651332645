import React from "react";
import fetch from 'node-fetch' 
import { osName, isIOS, isAndroid } from 'react-device-detect'
import { Base64 } from "js-base64";

const checkAnalytics = typeof window !== 'undefined' ? window.location.href.includes('?comeback&') : '';

if(checkAnalytics) {
  const accesedUrlReceived = typeof window !== 'undefined' ? (window.location.href.split('?comeback&').pop().split('&')) : '';  
  const accesedUrl = Base64.decode(accesedUrlReceived[0]).split('&');
  const clicked = typeof window !== 'undefined' ? window.location.href.includes('download') : '';
  const device = isAndroid ? 'android' : isIOS ? 'ios' : 'desktop';
  const os = osName;

  // get value by name
  function getParameterByName(name, url = typeof window !== 'undefined' ? window.location.href : '') {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const utmS = getParameterByName('utm_source')
  const utmM = getParameterByName('utm_medium')
  const utmC = getParameterByName('utm_content')
  const btn = getParameterByName('btn')
  
  let result = {
    event: 'ses_campaign_message_action_button_clicked',  
    os: os,
    device: device,
    msg_type: accesedUrl[0],
    toEmail: accesedUrl[1],
    uid: new Number(accesedUrl[2]),
    brand: accesedUrl[3],
    gmt_offset: accesedUrl[4],
    clicked: clicked ? 'download' : 'features',
    btn: btn ? btn : '',
    utm_source: utmS ? utmS : '',
    utm_medium: utmM ? utmM : '',
    utm_content: utmC ? utmC : ''
  }

  console.log(result, 'here')
  
  function safeStringify(obj) {
      const seen = [];
      return JSON.stringify(obj, (key, val) => {
        if (val != null && typeof val === 'object') {
          if (seen.indexOf(val) >= 0) {
            return;
          }
          seen.push(val);
        }
        return val;
      });
  }
  
  const dataString = safeStringify(result);
  
  fetch("https://reg3.bluemailapp.com/analytics", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: dataString
  });
}