import React from 'react'
import ReactWOW from 'react-wow'
import bannerImg from '../../assets/images/hero.webp'
import DropDownButton from '../App/DropDownButton'
import {isMobile} from 'react-device-detect'


const Banner = ({banner}) => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            {banner.title}
                            <div className="main-banner-divider" />
                            <p>{banner.subtitle}</p>
                            <DropDownButton />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12" style={{padding: isMobile ? '0' : ''}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image main-banner-image-mobile">
                                <img src={banner.image} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner