import React from "react";
import fetch from 'node-fetch' 
import { osName } from 'react-device-detect'

//this page send analytics to our srv all the time when any user access /download page
function getParameterByName(name, url = typeof window !== 'undefined' ? window.location.href : '') {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

let referrer = getParameterByName('utm_content');    
if (!referrer && typeof window !== 'undefined' && window.frames.top.document.referrer == 'https://t.co/') {
  referrer = 'tw_1a';
}

const os = osName;
let result = {
    event: 'site_download_viewed',  
    os: os ? os : '',
    referrer: referrer && referrer.includes('cb') ? 'comeback' : referrer && referrer.includes('sp') ? 'share' : referrer && referrer.includes('tw') ? 'twitter' : referrer && referrer.includes('nrm') ? 'nrm' : 'other',
}
if (referrer && referrer.includes('nrm')) {
    result.flow_session_id = getParameterByName('flow_session_id');
    result.session_id = getParameterByName('session_id');
    result.v = parseFloat(getParameterByName('v'));
}

function safeStringify(obj) {
    const seen = [];
    return JSON.stringify(obj, (key, val) => {
    if (val != null && typeof val === 'object') {
        if (seen.indexOf(val) >= 0) {
        return;
        }
        seen.push(val);
    }
    return val;
    });
}
  
const dataString = safeStringify(result);

fetch("https://reg3.bluemailapp.com/analytics", {
    method: 'POST',
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    },
    body: dataString
});